<template>
  <div class="noticeSystem">
    <div class="wrapper">
      <transition
        apear
        mode="out-in"
        name="noticeTransition"
        @after-leave="close()"
      >
        <div
          v-show="ready"
          class="noticeSystem__dialog"
        >
          <p class="noticeSystem__msg">
            {{ notices[mode] }}
          </p>
          <p class="noticeSystem__errMsg">
            {{ err }}
          </p>
          <button
            v-if="showCancelBtn"
            class="noticeSystem__btn noticeSystem__btn--cancel"
            type="button"
            @click="cancel()"
          >
            取消
          </button>
          <button
            v-if="showConfirmBtn"
            class="noticeSystem__btn noticeSystem__btn--confirm"
            type="button"
            @click="confirm()"
          >
            確定
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { EventBus } from '@/event-bus'

const messages = [
  null,
  { cancel: false, confirm: true },
  { cancel: false, confirm: true },
  { cancel: false, confirm: true },
  { cancel: false, confirm: true },
  { cancel: false, confirm: true },
  { cancel: false, confirm: true },
  { cancel: false, confirm: true }
]
export default {
  name: 'NoticeSystem',
  props: {
    mode: { type: Number, default: 0 },
    err: { type: String, default: '' }
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    showCancelBtn: function () {
      return messages[this.mode].cancel
    },
    showConfirmBtn: function () {
      return messages[this.mode].confirm
    },

    ...mapState({
      notices: (state) => state.notices
    })
  },
  mounted: function () {
    this.ready = true
  },
  methods: {
    redirectToRoot: function () {
      window.location.replace('./')
    },
    confirm: function () {
      switch (this.mode) {
        case 1:
          this.redirectToRoot()
          break
        case 2:
          this.transitionClose()
          break
        case 3:
          this.redirectToRoot()
          break
        case 4:
          this.transitionClose()
          break
        case 5:
          this.transitionClose()
          break
        case 6:
          this.transitionClose()
          break
        case 7:
          this.transitionClose()
          break
      }
    },
    transitionClose () {
      this.ready = false
    },
    close () {
      EventBus.$emit('close-notice')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.wrapper {
  background-color: $semi-black;
  background-image: none;
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noticeSystem {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.noticeTransition-enter-active {
  transition: margin 0.5s $specialBezier, opacity 0.3s ease-in-out;
}
.noticeTransition-leave-active {
  transition: margin 0.5s $specialBezier-reverse, opacity 0.3s ease-in-out;
}

.noticeTransition-enter,
.noticeTransition-leave-to {
  opacity: 0;
  margin-top: -20rem;
}

.noticeTransition-enter-to,
.noticeTransition-leave {
  opacity: 1;
  margin-top: 0;
}
.noticeSystem__dialog {
  display: inline-block;
  background-color: $color-white;
  min-width: 80%;
  max-width: 100%;
  padding: 20px;
}
.noticeSystem__msg {
  text-align: left;
}
.noticeSystem__errMsg {
  color: $color-gray;
  font-size: 0.7rem;
}

$w-noticeBtn: 118px;
$h-noticeBtn: 26px;
.noticeSystem__btn {
  color: $color-white;
  width: $w-noticeBtn;
  height: $h-noticeBtn;
  font-weight: 500;
  background: {
    color: $color-gold;
    image: url(../assets/game-bt.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  font-size: 0.9rem;
  line-height: 1;
  border: none;
  text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
    1px 1px 2px $color-black;

  &.noticeSystem__btn--cancel {
    background: {
      color: $color-gray;
      image: url(../assets/game-bt2.jpg);
    }
  }
  &.noticeSystem__btn--confirm {
    margin-left: 5px;
  }
}

::placeholder {
  color: $color-gray;
}
</style>
